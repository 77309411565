<template>
  <div>
    <div class="page__head d-flex">
      <div
        class="page__head__container ml-auto align-items-center justify-content-between"
      >
        <div class="page__head__text flex-fill"></div>
        <div class="page__head__actions" v-if="userCanCreateEmployerCat">
          <div class="d-flex">
            <button
              type="button"
              class="mx-2 button page__body__header__button button--grey text--capital"
              data-target="#newEmployersCategoryModal"
              data-toggle="modal"
            >
              <span class="d-inline-flex mr-2">new employers category</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <div class="page__body__header">
        <div
          class="page__head__container align-items-center justify-content-between"
        >
          <div class="page__head__text flex-fill">
            <h1 class="page__head__title">Employers categories</h1>
          </div>
        </div>
      </div>

      <div class="">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 85%"
              >
                name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in employersCategories"
              v-bind:key="item.categoryKey"
            >
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.name
                }}</span>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="button button--grey text--capital text--small d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editEmployersCategoryModal"
                      v-on:click="editEmployersCategory(item.categoryKey)"
                      v-if="userCanEditEmployerCat"
                      >edit</a
                    >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openDeleteConfirmation(item)"
                      v-if="userCanDeleteEmployerCat"
                      >delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="newEmployersCategoryModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="newEmployersCategoryModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Add a Employers Category
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEmployersCategoryForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employersCategoryForm.name"
                      v-bind:class="{
                        'input-required': employersCategoryFormErr.name,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Employers Category
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editEmployersCategoryModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="editEmployersCategoryModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Edit a EmployersCategory
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEmployersCategoryEditForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employersCategoryEditForm.name"
                      v-bind:class="{
                        'input-required': employersCategoryEditFormErr.name,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save EmployersCategory
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="employersCategoryToDelete != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this employers category
                  {{ employersCategoryToDelete.name }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this employers category from your list, you
                  won't be able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteEmployersCategory()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewEmployerCat"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import employersCategoryService from "@/core/services/general-setup-service/employers-category.service";
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default {
  name: "employers-category",
  components: {
    DeleteModal,
  },
  data: function () {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      // EmployersCategory
      employersCategories: [],
      employersCategoryToDelete: null,
      employersCategoryForm: {
        name: null,
      },
      employersCategoryFormErr: {
        name: false,
      },
      employersCategoryEditForm: {
        categoryKey: null,
        name: null,
      },
      employersCategoryEditFormErr: {
        name: false,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      fetchModel: {
        pageIndex: 1,
        pageSize: 10,
        name: null,
      },
      // End EmployersCategory
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    async getEmployersCategories() {
      this.isLoading = true;
      try {
        const res = await employersCategoryService.getEmployersCategories(
          this.fetchModel
        );
        this.employersCategories = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    getEmployersCategoryByKey(key) {
      try {
        this.isLoading = true;
        employersCategoryService.getEmployersCategoryByKey(key).then((res) => {
          this.employersCategoryEditForm = res.data.data;
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editEmployersCategory(categoryKey) {
      try {
        this.clearEmployersCategoryEditForm();
        this.getEmployersCategoryByKey(categoryKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveEmployersCategoryForm: async function (e) {
      e.preventDefault();
      if (this.validateEmployersCategoryForm()) {
        try {
          this.isSaving = true;
          await employersCategoryService.create(this.employersCategoryForm);
          this.isSaving = false;
          document.getElementById("newEmployersCategoryModalClose").click();
          this.clearEmployersCategoryForm();
          this.getEmployersCategories();
          this.alertSuccess("EmployersCategory added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEmployersCategoryEditForm: async function (e) {
      e.preventDefault();
      if (this.validateEmployersCategoryEditForm()) {
        try {
          this.isSaving = true;
          await employersCategoryService.update(
            this.employersCategoryEditForm.categoryKey,
            this.employersCategoryEditForm
          );
          this.isSaving = false;
          document.getElementById("editEmployersCategoryModalClose").click();
          this.clearEmployersCategoryEditForm();
          this.getEmployersCategories();
          this.alertSuccess("EmployersCategory updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(employersCategory) {
      this.employersCategoryToDelete = employersCategory;
    },
    async deleteEmployersCategory() {
      if (this.employersCategoryToDelete != null) {
        this.isDeleting = true;
        try {
          await employersCategoryService.delete(
            this.employersCategoryToDelete.categoryKey
          );
          this.employersCategoryToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("EmployersCategory deleted");
          this.isDeleting = false;
          this.getEmployersCategories();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },

    validateEmployersCategoryForm() {
      this.clearEmployersCategoryValidation();
      if (this.employersCategoryForm.name) {
        return true;
      }

      if (!this.employersCategoryForm.name) {
        this.employersCategoryFormErr.name = true;
      }
      return false;
    },
    clearEmployersCategoryForm() {
      this.employersCategoryForm = {
        name: null,
      };
    },
    clearEmployersCategoryValidation() {
      this.employersCategoryFormErr = {
        name: false,
      };
    },

    validateEmployersCategoryEditForm() {
      this.clearEmployersCategoryEditValidation();
      if (this.employersCategoryEditForm.name) {
        return true;
      }

      if (!this.employersCategoryEditForm.name) {
        this.employersCategoryEditFormErr.name = true;
      }
      return false;
    },
    clearEmployersCategoryEditForm() {
      this.employersCategoryFormEdit = {
        categoryKey: null,
        name: null,
      };
    },
    clearEmployersCategoryEditValidation() {
      this.employersCategoryEditFormErr = {
        name: false,
      };
    },

    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getEmployersCategories();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getEmployersCategories();
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getEmployersCategories();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateEmployerCat() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanCreateEmployerCategory;
    },
    userCanDeleteEmployerCat() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanDeleteEmployerCategory;
    },
    userCanEditEmployerCat() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanEditEmployerCategory;
    },
    userCanViewEmployerCat() {
      const employerCat = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employerCat;
      return permissionsList.CanViewEmployerCategory;
    },
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY : HH:MM");
    },
    formatShortDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
};
</script>
